@import '../../scss/style.scss';

.inline-checkbox {
    display: inline-block;
    vertical-align: middle;

    input[type="checkbox"] {
        display: none;

        &+ label {
            border: 1px solid $gray3;
            width: 18px;
            height: 18px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 4px;
            margin-right: 10px;
            position: relative;
            cursor: pointer;

            & + label {
                display: inline-block;
                vertical-align: middle;
                @include fontgroup($ubuntu, 13px, $light-black);
                cursor: pointer;
            }
        }

        &:checked {
            &+ label {
                border-color: $light-orange;

                &::before {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: $light-orange;
                    content: "";
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 2px;
                }
            }
        }
    }
}