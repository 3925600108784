@import '../../scss/style.scss';

header {
    width: 100%;
    background: rgba(54,70,76,.9);
    @include box-shadow(0, 1px, 5px, rgb(0 0 0 / 12%));
    @include transition(0.5s);
    padding: 20px 15px 20px 15px;
    z-index: 99;

    @media(min-width:1025px){
        position: fixed;
        padding: 12px 15px 12px 15px;
    }

    .container {
        grid-template-columns: 100%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        position: relative;
        grid-gap: 20px;

        .other-items {
            order: 1;
        }

        .navigation {
            order: 3;
            grid-column: 1/-1;
            margin-top: -16px;
        }

        @media(min-width:631px){
            grid-template-columns: 200px auto;
            text-align: left;
        }

        @media(min-width:1025px){
            grid-template-columns: 200px auto 300px;

            .other-items {
                order: 3;
            }
    
            .navigation {
                order: 2;
                grid-column: auto;
            }
        }
    }

    .logo-image{
        text-decoration: none;
        font-size: 25px;
        color: $white;
    }

    .other-items-ul {
        @include reset-list;
        text-align: right;
        
        @media(max-width:667px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .other-items-list {
                text-align: center;

                &.order-1 {
                    order: 1;
                }
                &.order-2 {
                    order: 2;
                }
                &.order-3 {
                    order: 3;
                }
                &.order-4 {
                    order: 4;
                    margin-top: 28px;
                    width: 100%;
                }
                
            }

        }
    }

    .other-items-list {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        position: relative;

        &:last-child {
            .other-items-list-anchor {
                svg {
                    top: -3px;
                    position: relative;
                }
            }
        }

        &:hover {
            .search-space-marker {
                opacity: 9;
                transform: translateY(0px);
                visibility: visible;
            }
        }

        .search-ic {
            display: none;

            @media(min-width:1025px){
                display: block;
            }
        }
    }

    .search-space-marker {

        @media(min-width:1025px){
            padding-top: 41px;
            position: absolute;
            top: 99%;
            right: 0;
            opacity: 0;
            transform: translateY(-10px);
            visibility: hidden;
            @include transition(0.5s);
        }
    }

    .search-bar-container {
        
        min-width: 250px;
        grid-template-columns: auto 47px;
        display: grid;

        @media(min-width:1025px){
            background: $gray1;
            padding: 30px;
        }
    }

    .input-styles {
        outline: none;
        border: none;
        background-color: $white;
        padding: 12px 15px;
    }

    .button-styles {
        background: $light-green;
        outline: none;
        border: none;
        color: $white;
        padding:0 9px;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
    }

    .other-items-list-anchor {
        @include fontgroup($ubuntu, 13px, $white);
        text-decoration: none;
        display: block;
        text-transform: uppercase;
        position: relative;

        .count {
            display: block;
            border-radius: 50%;
            text-align: center;
            width: 23px;
            height: 23px;
            line-height: 22px;
            font-weight: 300;
            font-size: 14px;
            color: $white;
            background: $light-orange;
            position: absolute;
            right: -15px;
            top: -11px;
            @include transition(1s);
        }

        &.icon {
            font-size: 22px;
        }

        &:hover {
            .count {
                background: $light-green;
                @include transition(1s);
            }
        }
    }

    &.sticky {
        @include transition(0.5s);
        padding: 30px 15px 30px 15px;

        @media(min-width:1025px){
            padding-top: 17px;
            padding-bottom: 17px;
        }

        .search-space-marker {
            padding-top: 28px;
        }
    }
}