@import "../scss/style.scss";

.cart-page {

    .cart-page-title {
        @include fontgroup($ubuntu, 20px, $light-black);
        font-weight: bold;
        line-height: 20px;
        text-transform: uppercase;
    }

    .cart-item-box {
        border-bottom: 1px solid $gray3;
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 20px;
        margin-top: 30px;
        align-items: center;

        @media(min-width:575px){
            grid-template-columns: 200px auto;
        }
    }

    .cart-item-details-title {
        @include fontgroup($ubuntu, 15px, $light-black);
        font-weight: normal;
        line-height: 20px;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray3;

        span {
            font-weight: bold;
            display: block;
            padding-top: 5px;
        }
    }

    .price-quantity {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 40% 60%;
        align-items: center;

        input {
            width: 50px;
            padding: 8px 10px;
            margin-left: 10px;
            border: 1px solid $gray3;
        }
    }

    .quantity {
        label {
            @include fontgroup($ubuntu, 15px, $light-green);
            font-weight: bold;
        }
    }

    .cart-price {
        @include fontgroup($ubuntu, 15px, $light-green);
    }

    .total-price {
        text-align: right;
        padding-bottom: 15px
    }

    .total {
        @include fontgroup($ubuntu, 15px, $light-black);
        text-transform: uppercase;

        span {
            color: $light-green;
        }
    }

    .subtotal-title {
        @include fontgroup($ubuntu, 15px, $light-black);
        text-transform: uppercase;
        padding: 12px 0;
        display: block;
        border-bottom: 1px solid $gray3;

        span {
            color: $light-green;
            float: right;
        }
    }

    .continue-or {
        display: block;
        text-align: center;

        a {
            display: block;
            text-decoration: none;
            padding: 15px 0;
            @include fontgroup($ubuntu, 15px, $light-green);
            
        }

        span {
            display: block;
            @include fontgroup($ubuntu, 14px, $light-black);
            padding: 15px;
        }

        .cart-common {
            @include fontgroup($ubuntu, 12px, $white);
            text-transform: uppercase;
            display: block;
            background: $light-green;
            border: none;
            padding: 12px 15px;
            border-radius: 5px;
            margin: 15px 0;
            font-weight: bold;
            margin: 0 auto;
        }

        @media(min-width:575px) {
            text-align: left;
            
            a {
                display: inline-block;
                vertical-align: middle;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }

            .cart-common {
                display: inline-block;
                vertical-align: middle;
                margin: 15px 0;
            }
        }
    }

    .note-strip {
        background-color: #fcf8e3;
        border-color: #faebcc;
        padding: 15px;
        margin: 20px 0;

        p {
            @include fontgroup($ubuntu, 14px, $gold);
        }
    }

    .special-instruction-title {
        @include fontgroup($ubuntu, 14px, $light-black);
        font-weight: bold;
        padding: 0 0 5px 0;
    }

    .special-instruction-text {
        width: 100%;
        display: grid;
        border: 1px solid $gray3;
        min-height: 70px;
        margin-top: 10px;
    }

    .payment-cards {
        padding: 10px 0;

        a {
            margin: 10px 10px 10px 0;
            display: inline-block;
        }
    }

    .list-group-item {
        .btn-light {
            font-size: 20px;
        }
        .row {
            align-items: center;
        }
    }
}

.total-detail-area {
    li {
        list-style: none;
        float: left;
        padding: 5px 0;
        width: 100%;

        &:last-child {
            text-transform: uppercase;
        }

        .title_area {
            float: left;
            font-weight: bold;
        }
        
        .price_area {
            float: right;
            font-weight: bold;
        }
    }
}


