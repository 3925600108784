@import '../scss/style.scss';

.gallery {
    img {
        width: 100%;
        max-width: 100%;
    }

    .gallery-image-section {
        overflow: hidden;
    }

    
    .left-button, 
    .right-button {
        border: none;
        font-size: 18px;
        padding: 0;
        line-height: 0;
        border-radius: 50%;
    }

    .right-button {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }

    .left-button {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }

    .big-image {
        padding: 30px;
        border: 1px solid $gray1;

        @media(max-width:767px) {
            padding: 20px;
        }
    }

    .thumb-image {
        margin: 15px 15px 0 15px !important;
        padding: 15px;
        border: 1px solid $gray1;
        width: 31% !important;

        @media(max-width:767px) {
            padding: 5px;
        }
    }

    .thum-images {
        padding: 0 5px !important;
    }
}