@import '../../scss/style.scss';

.best-section {
    margin-top: -74px;
    position: relative;
    display: grid;
    overflow: hidden;

    .container {
        max-width: 1100px;
    }

    .img-responsive {
        height: 70vh;
        object-fit: cover;
        float: left;
        width:100%;

        @media(min-width:767px) {
            height: 80vh;
        }

        @media(min-width:992px) {
            height: 100vh;
        }

        @media(min-width:1400px) {
            height: auto;
        }

        @media(max-width:767px){
            height: 400px;
        }
    }

    .best-text {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 9;
    }

    .best-title {
        @include fontgroup($ubuntu, 27px, $white);

        @media(min-width:768px) {
            font-size: 100px;
        }

        @media(min-width:575px) {
            font-size: 40px;
        }
    }

    .best-subtitle {
        @include fontgroup($ubuntu, 20px, $white);
        text-transform: uppercase;

        @media(min-width:768px) {
            font-size: 40px;
        }
    }

    .best-description {
        @include fontgroup($ubuntu, 15px, $white);
        padding: 15px;

        @media(min-width:768px) {
            font-size: 18px;
        }
    }

    .best-more {
        display: grid;
        text-decoration: none;
        color: $white;
        background: $light-green;
        max-width: 200px;
        min-width: 200px;
        padding: 15px;
        text-transform: uppercase;
        margin: 15px auto;
    }
}