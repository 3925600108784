@import '../scss/style.scss';

.error-page {
    padding: 150px 40px 80px 40px;
    text-align: center;
    min-height: 580px;
    display: flex;
    align-items: center;

    h2 {
        @include fontgroup($ubuntu, 50px, $light-green);
    }

    h3 {
        @include fontgroup($ubuntu, 75px, $light-green);
        padding-bottom: 15px;
    }

    h4 {
        @include fontgroup($ubuntu, 30px, $light-black);
        padding-bottom: 20px;
    }

    p {
        @include fontgroup($ubuntu, 17px, $black);

        a {
            color: $light-orange;
            text-decoration: none;
        }
    }
}