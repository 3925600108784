@import '../scss/style.scss';

.order-d {
    & > .list-group-item {
        position: relative;
        border: 1px solid $gray2;
        margin-bottom: 15px;
        z-index: 1;

        h2 {
            // color: $white;
            // padding-top: 2px;
            // margin-bottom: 21px;
        }

        // &::before {
        //     content: "";
        //     background-color: $light-black;
        //     width: 100%;
        //     left: 0;
        //     top: 0;
        //     height: 50px;
        //     position: absolute;
        //     z-index: -1;
        // }

        &:last-child {
            border-bottom:1px solid $gray2;
        }

        & > .list-group.list-group-flush .list-group-item {
            -webkit-box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
            -moz-box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
            box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
            margin-bottom: 15px;
        }

        h2, .h2 {
            font-size: 20px;

            @media(max-width:375px){
                padding-top: 5px;
                font-size: 15px;
            }
        }
    }
}

.order-summary {
    background-color: $light-black;

    h2 {
        margin: 0;
        color: $white;
        font-size: 20px;
    }
}