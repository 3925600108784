@import '../scss/style.scss';

.under-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;

    img {
        display: block;
        margin: 0 auto;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
    }

    h4 {
        font-size: 30px;
        font-weight: 900;
    }
    
    p {
        font-weight: 600;
        font-size: 18px;
    }
}