
.home {
    background: #f2f2f2;
}

.home .footer {
    padding-top: 150px;
    margin-top: -74px;
}

.featured-image-thumb {
    width: 120px;
    object-fit: cover;
    height: 125px;
}

.featured-product-thumb {
    width: 200px;
    object-fit: cover;
    height: 200px;
}

html {
    scroll-behavior: smooth;
}



/* @media(max-width:420px){
    h1, h2, h3, h4, h5, h6 {
        font-size: 20px;
    } 
} */