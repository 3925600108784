@import '../../scss/style.scss';

.blog-section {
    padding: 0 15px;
    margin-top: -74px;
    position: relative;

    .container {
        background: $white;
        padding: 30px 30px 30px 30px;
        grid-template-columns:100%;
        grid-gap: 20px;

        @media(min-width:1200px) {
            grid-template-columns: 50% 50%;
            padding: 30px 30px 0px 30px;
        }
    }

    .blog-box {
        display: grid;
        grid-template-columns: 100%;
        grid-gap:20px;

        @media(min-width:767px) {
            grid-template-columns: 250px auto;
        }
    }

    .date-blog {
        @include fontgroup($ubuntu, 16px, $light-green);
        font-weight: 400;

        span {
            font-size: 20px;
            color: $gray;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            position: relative;
            top: -3px;
        }
    }

    .blog-text {
        padding: 0 12px;

        @media(min-width:767px){
            padding: 30px;
        }
    }

    .blog-title {
        @include fontgroup($ubuntu, 18px, $light-black);
        text-transform: uppercase;
        font-weight: 400;
        padding: 10px 0;

        @media(min-width:767px) {
            font-size: 22px;
        }
    }

    .blog-description {
        @include fontgroup($ubuntu, 12px, $light-black);
        font-weight: 400;
        padding-bottom: 15px;
        line-height: 22px;
    }

    .read-arrow {
        color: $light-green;
    }
}