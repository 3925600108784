@import '../scss/style.scss';

.privacy-policy {
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0 80px 0;

    @media(min-width:1025px){
        padding: 150px 0 100px 0;
    }


    .container {
        padding: 0 20px;
    }

    h1 {
        font-size: 30px;
        padding-bottom: 15px;
        color: $black;
        font-family: $ubuntu;

        @media(max-width:500px){
            font-size: 17px;
        }
    }

    h3 {
        @media(max-width:500px){
            font-size: 15px;
        }
    }

    p {
        font-size: 14px;
        color: $black;
        padding: 15px 0;
        display: block;
        line-height: 24px;
        font-family: $ubuntu;
    }

    ul {
        display: block;
        padding: 15px 0;

        li {
            color: $black;
            font-size: 14px;
            position: relative;
            font-family: $ubuntu;
            padding: 5px 0;
        }

        &.dot {
            li {
                list-style: none;
                padding-left: 15px;
                
                &::before {
                    width: 5px;
                    height: 5px;
                    background-color: $black;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 12px;
                    border-radius: 50%;
                }
            }
        }

        &.numeric {
            padding-left: 15px;
            li {
                list-style: decimal;
            }
        }

        &.alpha {
            padding-left: 15px;
            li {
                list-style: lower-alpha;
            }
        }
    }

    .simple-ul {
        padding-left: 18px !important;
    }
}