@import "../scss/style.scss";

.ship-boxes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;

  .ship-col {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .ship-col-1 {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .box-1 {
    border: 1px dashed $gray;
    //border-radius: 5px;
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    -webkit-box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);
    box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);
    cursor: pointer;

    svg {
      font-size: 35px;
    }

    h3 {
      font-size: 19px;
      text-align: center;
    }
  }

  .box-2,
  .box-3 {
    border: 1px solid $gray2;
    //border-radius: 5px;
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    -webkit-box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);
    box-shadow: 10px 9px 31px -2px rgba(0, 0, 0, 0.22);

    input[type="radio"] {
      margin-right: 10px;
    }

    .input-style-box {
      margin-right: 15px;
      input[type="radio"] {
        display: none;
      }

      input[type="radio"] + label {
        width: 20px;
        height: 20px;
        background: url("/assets/images/select.png") left top no-repeat;
        border-radius: 50%;
        background-size: 100% 100%;
      }

      input[type="radio"]:checked + label {
        background: url("/assets/images/select-active.png") left top no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .common-ship-box {
    h4 {
      font-size: 15px;
    }

    ul {
      li {
        list-style: none;
        font-size: 14px;
      }
    }
  }
}

.payment-methods {
  .form-check {
    display: inline-block;
    margin-right: 15px;
  }
}

.title-adjustments {
  h1,
  h2,
  h3 {
    font-size: 2rem;
    span {
      padding-top: 8px;
      font-size: 15px;
      display: block;
    }
    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }
}
