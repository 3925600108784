@import '../../scss/style.scss';

.footer {
    display: grid;
    width: 100%;
    background: $light-black;
    padding: 50px 0 0px 0;
}

.footer-top {
    border-bottom: 1px solid rgba(255,255,255,.3);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    .container {
        display: grid;
        grid-template-columns: 100%;
        grid-gap:20px 50px;
        text-align: center;

        .omas-brief {
            grid-column: 1/-1;
            padding-bottom: 20px;
        }

        @media(min-width:480px) {
            grid-template-columns: 1fr 1fr;
            text-align: left;
        }

        @media(min-width:700px) {
            grid-template-columns: auto auto auto auto;
        }

        @media(min-width:1025px){
            grid-template-columns: 280px auto auto auto auto;
            
            .omas-brief {
                grid-column: 1;
            }
        }

        @media(max-width:1024px) {
            .foot-details {
                grid-column: 1/-1;
                padding-top: 20px;
            }
        }
    }

    .logo-footer {
        @include fontgroup($ubuntu, 35px, $white);
        padding-bottom: 15px;
    }

    .foot-description {
        opacity: 0.5;
        @include fontgroup($ubuntu, 14px, $white);
        line-height: 22px;
    }

    .menu-title {
        @include fontgroup($ubuntu, 14px, $white);
        text-transform: uppercase;
        font-weight: normal;
        padding-bottom: 10px;
    }

    .menu-list {
        list-style: none;
        padding: 5px 0;
    }

    .menu-items-link {
        font-size: 12px;
        text-transform: uppercase;
        color: $extra-light;
        text-decoration: none;
    }

    .newsletter-wrapper {
        padding: 1px 19px 20px;
        background: $light-green;
    }

    .newsletter-wrapper-title {
        @include fontgroup($ubuntu, 15px, $white);
        text-transform: uppercase;
        padding: 10px 0 10px 0;
    }
    
    .input-foot {
        width: calc(100% - 51px);
        padding: 10px 15px;
        height: 40px;
        border: none;
        float: left;
    }

    .button-foot {
        height: 40px;
        padding: 0;
        text-align: center;
        line-height: 38px;
        font-weight: 400;
        width: 51px;
        background: $light-orange;
        border: none;
        @include fontgroup($ubuntu, 15px, $white);
    }

    .footer-detail-description {
        @extend .foot-description;
        padding: 0px 0 15px 0;
    }

    .number-foot {
        @include fontgroup($ubuntu, 22px, $gray);
        text-decoration: none;
        font-weight: 500;
    }

    .social-links{
        margin-top: 10px
    }

    .social-items {
        @include fontgroup($ubuntu, 18px, $gray);
        padding: 10px 10px 10px 0px;
        display: inline-block;
        @include transition(1s);

        &:hover {
            color: $white;
            @include transition(1s);
        }
    }
}

.footer-bottom {
    padding: 20px 15px;
    position: relative;

    .footer-copy-right {
        @include fontgroup($ubuntu, 13px, $gray);
        text-align: center;
        color: $white;

        a {
            color: $gray;
            text-decoration: none;
        }
    }

    .back-to-top {
        color: $white;
        background: $light-green;
        width: 30px;
        text-align: center;
        font-size: 20px;
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 99;
    }
}