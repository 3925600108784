@import '../../scss/style.scss';

.product-detail-section {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;

    @media(min-width:768px){
        grid-template-columns: 300px auto;
    }

    @media(min-width:992px){
        grid-template-columns: 400px auto;
    }


    .product-slider {
        display: grid;

        .swiper-container {
            width: 100%;
        }

        h3 {
            text-align: center;
        }
    }

    .big-image {
        padding: 30px;
        border: 1px solid $gray1;

        img {
            width: 100%;
        }
    }

    .thum-images {
        width: calc(100%);
        display: block;
        position: relative;
        margin-top: 30px;
    }

    .thum-image {
        width: calc(33.33% - 30px);
        float: left;
        border: 1px solid $gray1;
        padding: 10px;
        margin-right: 15px !important;
        margin-left: 15px !important;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    .left-button , .right-button {
        border: none;
        font-size: 18px;
        padding: 0;
        line-height: 0;
        border-radius: 50%;
    }
    
    .left-button {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }
    
    .right-button {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }

    .product-details-title {
        @include fontgroup($ubuntu, 16px, $light-black);
        font-weight: normal;
        padding-bottom: 15px;
    }

    .product-price {
        @include fontgroup($ubuntu, 16px, $light-green);
        font-weight: 500;
        padding-bottom: 15px;

        span {
            text-decoration: line-through;
            padding-left: 5px;
        }
    }

    .product-amount {
        @include fontgroup($ubuntu, 16px, $light-green);
        font-weight: normal;
        padding-bottom: 20px;
        font-weight: 500;
    }

    .product-quantity-button {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        @media(min-width:480px){
            flex-direction: row;
        }
    }

    .product-qty {
        margin-right: 5px;
        label {
            @include fontgroup($ubuntu, 14px, $light-black);
            font-weight: 500;
            padding-right: 5px;
        }

        .product-value {
            border: 1px solid $gray1;
            padding: 8px;
            max-width: 50px;
            border-radius: 2px;
        }
    }

    .product-button-group {
        button {
            @include fontgroup($ubuntu, 13px, $light-black);
            background: $light-green;
            border: 1px solid $light-green;
            color: $white;
            padding: 8px 15px;
            text-transform: uppercase;
            margin-right: 5px;
            border-radius: 2px;
            margin-top: 20px;
            cursor: pointer;

            @media(min-width:480px){
                margin-top: 0;
            }

            &:disabled {
                background: $gray1;
                color: $light-black;
                cursor: default;
            }
        }
    }

    .product-info {
        @include fontgroup($ubuntu, 14px, $light-black);
        padding: 8px 0;

        span {
            padding-left: 5px;
            color:$light-green;
        }

        img {
            max-width: 70px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    .product-description-title {
        @include fontgroup($ubuntu, 14px, $light-black);
        padding: 15px 0 8px 0;
    }

    .product-description {
        @include fontgroup($ubuntu, 14px, $light-black);
        padding: 0px 0 8px 0;
        line-height: 22px;
    }

    .contact-button {
        @include fontgroup($ubuntu, 13px, $light-black);
            background: $light-orange;
            border: 1px solid $light-orange;
            color: $white;
            padding: 8px 15px;
            text-transform: uppercase;
            margin-right: 5px;
            border-radius: 2px;
            margin-top: 25px;
    }

    .btn-qty-popup {
        border: none;
        outline: none;
        background: none;
        text-decoration: underline;
    }
}

.slider-title {
    padding-bottom: 20px;
    @include fontgroup($ubuntu, 18px, $light-black);
}

.slider-description {
    padding-top: 30px;
    @include fontgroup($ubuntu, 14px, $light-black);
    line-height: 24px;
}


.form-popup {

    h5 {
        text-transform: capitalize;
        text-align: center;
        position: relative;
        font-size: 14px;
    }

    h5 span {
        z-index: 1;
        background: #fff;
        position: relative;
        padding: 0 5px;
    }

    h5:before {
        content: "";
        height: 1px;
        width: 100%;
        background: $gray2;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .react-popup .react-popup-inner {
        max-width: 400px;
    }

    label {
        float: left;
        width: 100%;
    }

    input[type="text"]{
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding: 5px 15px;
        border: 1px solid $gray1;
    }

    textarea {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding: 5px 15px;
        border: 1px solid $gray1;
        height: 65px;
        resize: none;
    }

    .submit_btn {
        background: $light-green;
        color: $white;
        padding: 5px 10px;
        border: none;
        outline: none;
        text-transform: uppercase;
        font-size: 14px;

        &:hover {
            background: $light-orange;
        }
    }

    .cancel_btn {
        background: $light-black;
        color: $white;
        padding: 5px 10px;
        border: none;
        outline: none;
        text-transform: uppercase;
        font-size: 14px;
        margin-left: 15px;

        &:hover {
            background: $light-orange;
        }
    }
}

.product-slider.gallery_show {
    max-width: 504px;
    margin: 0 auto;
}

.form-popup {
    .slider-title {
        padding-bottom: 9px;
    }
}