@import '../../scss/style.scss';

.modal-open {
    overflow: hidden;
}

.react-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    overflow-y: scroll;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    .react-popup-inner {
        position: relative;
        padding: 30px;
        width: 95%;
        max-width: 650px;
        background-color: $white;
        margin-top: 100px;
        margin-bottom: 40px;

        .react-popup-close {
            position: absolute;
            right: 6px;
            top: 6px;
            background-color: $light-green;
            border: none;
            color: $white;
            font-size: 22px;
            line-height: 0;
            cursor: pointer;
        }
    }
}

