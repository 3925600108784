@import "../scss/style.scss";

.product-display {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;

    @media(min-width:575px){
        grid-template-columns: 200px auto;
    }

    .product-display-title {
        @include fontgroup($ubuntu, 18px, $light-black);
        padding-bottom:15px;
    }

    .product-display-description {
        @include fontgroup($ubuntu, 14px, $light-black);
        padding-bottom:15px;
        line-height: 20px;
    }
}

// filter bar

.filter-bar {
    padding: 10px;
    background: $gray2;
    margin: 30px 0;
    display: block;
    text-align: right;

    .sort-boxes {
        display: inline-block;
    }

    .sort-boxes-title {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        margin-left: 15px;
        @include fontgroup($ubuntu, 14px, $light-black);
    }
    .show {
        display: inline-block;
        vertical-align: middle;
        background: $white;
        padding: 5px;
        border: none;
        display: inline-block;
    }
}

// Product items

.product-all {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;

    @media(min-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(min-width:992px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media(min-width:1200px) {
        grid-template-columns: repeat(4, 1fr);
    }

    .img1 {
        width: 200px;
        display: block;
        margin: 0 auto;
    }

    .img2 {
        width: 200px;
        display: none;
    }
    
    .product-item {
        // cursor: pointer;
        overflow: hidden;
        position: relative;
        padding: 0 20px 26px;
        text-align: center;
        background: $white;
        border: 1px solid $gray3;

        &:hover {
            .img1 {
                display: none;
            }
        
            .img2 {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .product-item-image {
        padding: 20px 0;
        min-height: 150px;
        display: grid;
    }

    .price {
        text-align: center;
        color: $light-green;

        .sub-price {
            text-decoration: line-through;
            @include fontgroup($ubuntu, 16px, $light-orange);
            font-weight: normal;
            padding-left: 5px;
        }
    }

    .product-item-title {
        @include fontgroup($ubuntu, 14px, $light-black);
        font-weight: normal;
        padding: 10px 0;
        margin-bottom: 0;
    }

    .product-item-description {
        @include fontgroup($ubuntu, 13px, $light-black);
        font-weight: normal;
        line-height: 20px;
    }

    .pro-button-style {
        width: 100%;
        float: left;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 56px;
        background: $light-green;
        border: none ;
        color: #ffffff;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
            background-color: $light-orange;
        }

        svg  {
            font-size: 22px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
        }
    }

}

// Pagination bar

.pagination-bar {
    padding: 10px;
    background: $gray2;
    margin: 30px 0;
    display: block;

    .page-number {
        float: left;
        font-size: 13px;
        font-weight: bold;
        line-height: 30px;
        color: $light-black;
        width: 100%;
        text-align: center;

        @media(min-width:480px) {
            width: auto;
        }
    }
    
    .page-anchors {
        float: right;
        width: 100%;
        text-align: center;
        margin:10px 0;

        @media(min-width:480px) {
            width: auto;
            margin:0;
        }
    }

    .page-num {
        padding: 4px 16px;
        background: $light-orange;
        color: #ffffff;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        display: inline-block;
        text-decoration: none;
        margin-left: 5px;
        line-height: 24px;

        &:hover {
            background-color:$light-green;
        }

        &.active {
            background-color:$light-green;
        }

        svg {
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
        }

        &.hide {
            display: none;
        }
    }
}

.seller-name {
    @include fontgroup($ubuntu, 12px, $light-black);
    font-weight: normal;
    padding: 0px 0;
}