@import '../../scss/style.scss';

.home-hero {
    position: relative;
    overflow: hidden;

    .container {
        max-width: 1100px;
    }

    .img-responsive {
        height: 70vh;
        object-fit: cover;
        float: left;
        width: 100%;

        @media(min-width:767px) {
            height: 80vh;
        }

        @media(min-width:992px) {
            height: 100vh;
        }

        @media(min-width:1400px) {
            height: auto;
        }

        @media(max-width:767px) {
            height: 510px;
        }
    }

    .hero-text {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 9;
    }

    .hero-main-title {
        @include fontgroup($ubuntu, 40px, $white);

        @media(min-width:768px) {
            font-size: 100px;
        }
    }

    .hero-sub-title {
        @include fontgroup($ubuntu, 20px, $white);

        @media(min-width:768px) {
            font-size: 40px;
        }
    }

    .hero-description {
        @include fontgroup($ubuntu, 15px, $white);
        padding: 15px;

        br {
            display: none;

            @media(min-width:480px){
                display: block;
            }
        }

        @media(min-width:768px) {
            font-size: 18px;
        }
       
    }
}