@import "../../scss/style.scss";

.navigation {
  .main-list {
    @include reset-list;

    @media(min-width:993px){
      margin: 16px 0 0 0;
    }
  }

  .main-list-item {
    display: inline-block;
    vertical-align: middle;

    &.active {
      .big-menu-spacer {
        opacity: 9;
        z-index: 1;
        transform: translateY(0px);
        visibility: visible;
        @include transition(0.5s);
      }
    }
  }

  .big-menu-spacer {
    width: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 100%;
    transform: translateY(-5px);
    visibility: hidden;
    opacity: 0;
    @include transition(0.2s);
    padding-top: 11px;
    overflow: hidden;
  }

  .main-list-item-anchor {
    @include fontgroup($ubuntu, 12px, $extra-light);
    @include transition(0.8s);
    display: block;
    margin: 0 8px 0;
    line-height: 50px;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;

    &:hover {
      @include transition(0.8s);
      color: $white;
    }

    &.active {
      color: $white;
    }

    @media(min-width:993px){
      line-height: 0;
    }
  }

  .big-menu {
    background: $white;
    padding: 30px;
    display: grid;
    z-index: 999;

    &.style-1 {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
    }

    &.style-2 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      img {
        width: 100%;
      }
    }

    &.style-3 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .style-1-title {
    padding-bottom: 10px;

    .title-anchor {
      @include fontgroup($ubuntu, 15px, $light-black);
      text-decoration: none;
      font-weight: 500;
      @include transition(0.8s);

      &:hover {
        color: $light-orange;
        @include transition(0.8s);
      }
    }
  }

  .style-1-ul {
    .style-1-list {
      list-style: none;
      padding: 2px 0;
    }

    .style-1-anchor {
      @include fontgroup($ubuntu-condensed, 14px, $light-black);
      text-decoration: none;
      font-weight: 500;
      @include transition(0.5s);

      &:hover {
        color: $light-orange;
        @include transition(0.5s);
      }
    }
  }

  nav {
    @media (max-width: 992px) {
      display: none;

      &.active {
        display: flex;
        flex-direction: column;
        width: 100%;

        .main-list-item {
          display: block;
        }

        .big-menu-spacer {
          position: static;
          visibility: visible;
          opacity: 9;
          // height: 0;
          padding-top: 0;
          display: none;
          flex-direction: column;
        }

        .active {
          .big-menu-spacer {
            display: flex;
          }
        }

        .big-menu.style-1 {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}

header {
  &.sticky {
    .big-menu-spacer {
      padding-top: 17px;
    }
  }
}

.hamburger {
  display: none;
  color: $white;
  font-size: 20px;

  svg {
    color: $white;
  }

  @media (max-width: 992px) {
    display: block;
    float: right;
  }

  @media (max-width: 630px) {
    float: none;
    margin: 0 auto;
  }
}
