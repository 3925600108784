
$white: #fff;
$black: #000;
$light-black: #404f4d;
$extra-light:#bceded;
$light-orange: #fcb28e;
$light-green:#558888;
$gold: #c09853;
$gray: #888888;
$gray1: #f2f2f2;
$gray2: #e3e3e3;
$gray3: #ecebeb;
