@import '../scss/style.scss';

.profile-tabs {
    width: 100%;
    .react-tabs__tab-list {
        border: none;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .react-tabs__tab {
        background-color: rgba(54, 70, 76, 0.9);
        color: $white;
        border-radius: 2px;
        margin: 0 15px 0 0;
        border: none;

        @media(max-width:480px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .react-tabs__tab--selected {
        border: none;
        color: $white;
        background-color: $light-green;
    }

    .table-responsive {
        width: 100%;
        float: left;

        table {
            @media(max-width:767px){
                width: 767px;
            }
        }
    }
}