@import './colors.scss';
@import './fonts.scss';
@import './utility.scss';

* {
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1250px;
    display: grid;
    margin: 0 auto;
}

.img-responsive {
    max-width: 100%;
}

.without-hero-sections {
    padding:40px 15px;
    background: $gray1;

    .container {
        background: $white;
        padding: 30px;
    }

    @media(min-width:1025px){
        padding-top: 150px;
    }
}

.bread-crumbs {
    background-color: $light-green;
    margin-bottom: 30px;

    ul {
        padding-left: 10px;
        margin: 0;
        li {
            display: inline-block;
            list-style: none;
            padding: 10px 0px 10px 10px;

            &:last-child {
                a:after {
                    display: none;
                }
            }

            a {
                color: $white;
                text-decoration: none;
                text-transform: capitalize;

                &:after {
                    content: "/";
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
    }
}

.profile-page {

    .without-hero-sections {
        .container {
            @media(max-width:667px){
                display: flex;
            }
        }
    }
    .row {
        max-width: 100%;
        margin: 0;
    }
    .table-responsive {

        @media(max-width:667px){
            overflow-x: scroll;
        }
    }
    .col-md-3,
    .col-md-9 {
        padding-bottom: 30px;
    }
}