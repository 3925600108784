@mixin reset-list {
    margin: 0;
    padding: 0;
}

@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default) {
    box-shadow: $x-axis $y-axis $blur $color;
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
    -moz-box-shadow: $x-axis $y-axis $blur $color;
    -ms-box-shadow: $x-axis $y-axis $blur $color;
}

@mixin transition($x...){
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

@mixin fontgroup($font-family,$font-size,$color) {
    font-family: $font-family;
    font-size: $font-size;
    color: $color;
}