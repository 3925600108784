@import "../scss/style.scss";

.checkout-page {
    padding: 0 15px;

    .container {
        display: grid;
        grid-template-columns: 100%;

        @media(min-width:992px){
            grid-template-columns: auto 480px;
        }
    }

    .form-colum {
        padding: 40px 0 0 0;
        
        order: 1;

        @media(min-width:992px){
            order: 0;
            border-right: 1px solid $gray3;
            padding-right: 50px;
        }
    }

    .form-colum-title {
        @include fontgroup($ubuntu, 22px, $light-black);
        font-weight: normal;
        margin-bottom: 15px;
    }

    .form-colum-breadcrumb {
        ul {
            li {
                display: inline-block;
                vertical-align: middle;
                padding: 0px;

                &:after {
                    display: inline-block;
                    content: ">";
                    vertical-align: middle;
                    padding: 0 10px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                a {
                    text-decoration: none;
                    display: inline-block;
                    vertical-align: middle;
                    color: $light-orange;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    .contact-information {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 30px;

        @media(min-width: 575px) {
            flex-direction: row;
        }
    }

    .contact-information-title {
        @include fontgroup($ubuntu, 18px, $light-black);
        font-weight: normal;
        margin-bottom: 20px;
    }

    .contact-information-login {
        @extend .contact-information-title;
        font-size: 16px;

        a {
            text-decoration: none;
            color: $light-orange;
        }
    }

    .input-field {
        float: left;
        width: 100%;
        border: 1px solid $gray3;
        padding: 15px 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        @include fontgroup($ubuntu, 15px, $light-black);
    }

    .shipping-address-title {
        @include fontgroup($ubuntu, 22px, $light-black);
        margin-top: 35px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .shipping-address-form {
        display: grid;
    }

    .dual-colum {
        display: grid;
        grid-template-columns:100%;
        grid-gap:10px;

        @media(min-width:575px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .three-colum {
        display: grid;
        grid-template-columns: 100%;
        grid-gap:10px;

        @media(min-width:575px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .btn-group-checkout {

        a {
            text-decoration: none;
            display: inline-block;
            vertical-align: middle;
            @include fontgroup($ubuntu, 15px, $light-orange);
            margin: 30px 0;

        }
    }

    .checkout-shopping {
        display: inline-block;
        vertical-align: middle;
        background-color: $light-orange;
        padding: 15px;
        color: $light-black;
        @include fontgroup($ubuntu, 15px, $light-black);
        border: none;
        border-radius: 5px;
        margin: 30px 15px 20px 0;
    }


    .all-right-reserved {
        border-top: 1px solid $gray3;
        margin-top: 30px;
        padding: 20px 0;

        p {
            @include fontgroup($ubuntu, 12px, $light-black);  
        }
    }

    .items-colum {
        padding: 40px 0 0 0px;

        @media(min-width:992px){
            order: 1;
            padding: 40px 0 0 50px;
        }

        .single-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }

        .item-detail {
            display: inline-block;
        }

        .image-sec {
            width: 70px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid $gray3;
            border-radius: 5px;
            position: relative;

            i {
                font-style: normal;
                width: 25px;
                height: 25px;
                background-color: $gray;
                display: block;
                color: $white;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                top: -10px;
                right: -10px;
            }

            img {
                width: 100%;
                float: left;
            }
        }

        .item-text {
            display: inline-block;
            vertical-align: middle;
            padding-left: 15px;
            @include fontgroup($ubuntu, 15px, $light-black); 
        }

        .item-price {
            @include fontgroup($ubuntu, 15px, $light-black); 
        }

        .sub-section {
            border-top: 1px solid $gray3;
            border-bottom: 1px solid $gray3;
            padding: 10px 0;
        }

        .sub-section-title {
            padding:8px 0;
            font-weight: normal;
            @include fontgroup($ubuntu, 13px, $light-black); 

            span {
                float: right;
            }
        }

        .checkout-total {
            @include fontgroup($ubuntu, 15px, $light-black);
            font-weight: normal; 
            padding: 30px 0;

            span {
                float: right;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}